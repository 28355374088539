import React, { useMemo, useCallback, useEffect } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Box, Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Logo from "../../../assets/logo.png";
import RequestsApi from '../../Requests/RequestsApi';
// import FieldPhone from '@dex/bubl-dash/src/lib/components/Fields/FieldPhone';
import Alert from '@dex/bubl-dash/src/lib/components/Alert/Alert';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';

const DashboardGet: React.FC<any> = (props: any) => {

    const app = useApp();

    const form = useForm({ mid: '', tid: '' });

    const process = RequestsApi.getPaper();

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values
        });

    }, []);

    const resetData = useCallback(() => {

        form.reset();
        process.reset();

    }, []);

    useEffect(() => {

        app.toggleMenu(null, false);

    }, []);

    const data = process.data;

    return useMemo(() => (

        <Box
            justify={'center'}
            windowHeight={true}
        >

            <Row
                gutter={'auto'}
                justify={'center'}
            >

                <Col
                    col={24}
                >

                    <Space />
                    <div style={{ textAlign: "center" }}>
                        <img src={Logo} alt="Paper" width={200} />
                    </div>
                    <Space />

                </Col>

                {!process.data &&

                    <Col
                        col={24}
                        className={'w-420'}
                    >

                        <Panel
                            heading={"Get Paper Roll"}
                        >

                            <Form
                                form={form}
                                onSubmit={handleSubmit}
                                loading={process.loading}
                            >

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24 }}>

                                        <FieldText
                                            form={form}
                                            label={"MID"}
                                            name='mid'
                                            required={true}
                                            defaultValue={form.defaults.mid}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24 }}>

                                        <FieldText
                                            form={form}
                                            label={"TID"}
                                            name='tid'
                                            required={true}
                                            defaultValue={form.defaults.tid}
                                        />

                                    </Col>

                                </Row>

                                <Line />

                                <Button
                                    label={"Submit"}
                                    block={true}
                                    loading={process.loading}
                                />

                            </Form>

                        </Panel>


                    </Col>

                }

                {data &&

                    <Col
                        col={24}
                        className={'w-1200'}
                    >

                        {data.existing &&
                            <>
                                <Alert
                                    message={"Request In Progress"}
                                    description={"Your Paper Roll Request Is Already In Progress."}
                                    type={"info"}
                                />
                            </>
                        }


                        {!data.existing &&
                            <>
                                <Alert
                                    message={"Thank You!"}
                                    description={"Your Paper Roll Request Has Been Successfully Received."}
                                    type={"success"}
                                />
                            </>
                        }

                        <Space />

                        <Panel
                            heading={data.request.id}
                        >

                            <MetaTable
                                data={data.request}
                                columnA={[
                                    {
                                        label: "Merchant",
                                        key: "merchant.name",
                                    },
                                    {
                                        label: "MID",
                                        key: "merchant.mid",
                                    },
                                    {
                                        label: "TID",
                                        key: "merchant.tid",
                                    },
                                    {
                                        label: "Address",
                                        key: "merchant.address",
                                        format: "address"
                                    },

                                ]}
                                columnB={[
                                    {
                                        label: "Product",
                                        key: "productMeta.name",
                                    },
                                    {
                                        label: "Number of Rolls",
                                        key: "productMeta.numberOfRolls",
                                        format: "number"
                                    },
                                    {
                                        label: "Diameter (mm)",
                                        key: "productMeta.diameter",
                                        format: "number"
                                    },
                                ]}
                                columnC={[
                                    {
                                        label: "Status",
                                        key: "status",
                                        format: "titleCase",
                                    },
                                    {
                                        label: "Shipping Provider",
                                        key: "shippingProvider",
                                        format: "modelLink",
                                    },
                                    {
                                        label: "Tracking No.",
                                        key: "trackingNo",
                                    }
                                ]}
                            />

                        </Panel>

                        <Space />

                        <Box align={"center"}>
                            <Button
                                onClick={resetData}
                                label={"Go Back"}
                            />
                        </Box>


                    </Col>

                }

            </Row>



        </Box>

    ), [form, process]);

}

export default DashboardGet;
