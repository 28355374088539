import ProductsIndex from './ProductsIndex';
import ProductsCreate from './ProductsCreate';
import ProductsSettings from './ProductsSettings';
import ProductsSingle from './ProductsSingle';

export default function (app) {

    const settings = ProductsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 13,
        items: [
            {
                label: "All Products",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Small",
                path: settings.path + "/boxSize/small",
                caps: settings.indexCaps
            },
            {
                label: "Medium",
                path: settings.path + "/boxSize/medium",
                caps: settings.indexCaps
            },
            {
                label: "Large",
                path: settings.path + "/boxSize/large",
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.indexCaps
            }
        ],
    });

    ProductsIndex(app, settings);

    ProductsCreate(app, settings);

    ProductsSingle(app, settings);

}