import DashboardSettings from './DashboardSettings';
// import DashboardIndex from './Components/DashboardIndex';
import SimplePage from '@dex/bubl-dash/src/lib/pages/SimplePage';
import DashboardGet from './Components/DashboardGet';
import DashboardIndex from './Components/DashboardIndex';

export default function (app) {

    const settings = DashboardSettings;

    app.addRoute({
        name: "home",
        path: "/",
        zone: settings.zone,
        caps: [],
        sidebar: false,
        component: SimplePage,
        componentProps: {
            page: DashboardIndex,
        }
    });

    // app.addRoute({
    //     name: "dashboard",
    //     path: "dashboard",
    //     zone: settings.zone,
    //     caps: [],
    //     component: SimplePage,
    //     componentProps: {
    //         header: {
    //             title: "Dashboard"
    //         },
    //         page: DashboardReports,
    //     }
    // });

    // app.addRoute({
    //     name: "reports",
    //     path: "reports",
    //     zone: settings.zone,
    //     caps: [],
    //     component: SimplePage,
    //     componentProps: {
    //         header: {
    //             title: "Reports"
    //         },
    //         page: DashboardReports,
    //     }
    // });

    app.addRoute({
        name: "get",
        path: "get",
        zone: false,
        caps: [],
        public: true,
        component: DashboardGet,
    });

}