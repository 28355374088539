import React, { useCallback, useMemo } from 'react';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Space } from '@dex/bubl-dash';
import { Line } from '@dex/bubl-dash';
import { DataTable } from '@dex/bubl-dash';
import { Alert } from '@dex/bubl-dash';

const MerchantsImportReviewForm: React.FC<any> = (props: any) => {

    const { handleReset, merchants } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const getClassByStatus = useCallback((row) => {

        return row.rowStatus ? "table-bg-green" : "table-bg-red";

    }, []);

    const columns = [
        {
            label: "Merchant ID",
            key: "merchant",
            format: "modelLink",
            style: { minWidth: 200 }
        },
        {
            label: "MID",
            key: "merchant.mid",
        },
        {
            label: "Company Name",
            key: "merchant.companyName",
        },
        {
            label: "Trading Name",
            key: "merchant.tradingName",
        },
        {
            label: "Email Address",
            key: "merchant.email",
            style: { minWidth: 160 }
        },
        {
            label: "Phone Number",
            key: "merchant.phone",
            style: { minWidth: 200 }
        },
        {
            label: "Status",
            key: "merchant.status",
            style: { minWidth: 160 }
        },
        {
            label: "Vendor",
            key: "vendor",
            format: "modelLink",
            style: { minWidth: 160 }
        },
        {
            label: "Product",
            key: "product",
            format: "modelLink",
            style: { minWidth: 160 }
        },
        {
            label: "Shipping Address Line 1",
            key: "merchant.line1",
            style: { minWidth: 160 }
        },
        {
            label: "Shipping Address Line 2",
            key: "merchant.line2",
            style: { minWidth: 160 }
        },
        {
            label: "Zip Code",
            key: "merchant.zipcode",
            style: { minWidth: 160 }
        },
        {
            label: "City",
            key: "merchant.city",
            style: { minWidth: 160 }
        },
        {
            label: "State",
            key: "merchant.state",
            style: { minWidth: 160 }
        },
        {
            label: "Country",
            key: "merchant.country",
            style: { minWidth: 160 }
        },
    ];

    return (
        <>

            {merchants &&
                <>

                    {merchants.success > 0 &&
                        <>
                            <Alert
                                type={"success"}
                                description={merchants.success + " Requests Successfully Created."}
                            />
                            <Space />
                        </>
                    }

                    {merchants.error > 0 &&
                        <>
                            <Alert
                                type={"error"}
                                description={merchants.error + " Errors Occurred. Please check the list below."}
                            />
                            <Space />
                        </>
                    }

                    <DataTable
                        data={merchants.list}
                        columns={columns}
                        rowClassName={getClassByStatus}
                    />

                </>
            }

            <Line />

            <ButtonRow>

                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={handleReset}
                />

            </ButtonRow>

        </>
    );

}

export default MerchantsImportReviewForm;
