import { options, response, apiPost, apiGet } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = '/deliveries';

const DashboardApi: any = {

    endpoint: endpoint,

    requestAnalysis: (options: options = {}): response => {

        return apiPost({
            url: endpoint + "/requestAnalysis",
            ...options
        });

    },

    deliveryHistory: (options: options = {}): response => {

        return apiPost({
            url: endpoint + "/deliveryHistory",
            ...options
        });

    },

    dashboard: (options: options = {}): response => {

        return apiGet({
            url: endpoint + "/dashboard",
            ...options
        });

    }

};

export default DashboardApi;