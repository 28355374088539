import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import RequestsApi from '../RequestsApi';
import RequestsCreateFormCheck from './RequestsCreateFormCheck';
import RequestsCreateFormReview from './RequestsCreateReview';
import RequestsCreateFormValidate from './RequestsCreateFormValidate';

const RequestsCreateForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const merchants = RequestsApi.create({}, "check");

    const [step, setStep] = useState("check");

    const handleReset = useCallback(() => {

        form.reset();
        process.reset();
        merchants.reset();

        setStep("check");

    }, []);

    const handleCheck = useCallback((values) => {

        merchants.run({
            data: values
        })

    }, []);

    useEffect(() => {

        let step = "check";

        if (merchants.data) step = "validate";
        if (process.data) step = "review";

        setStep(step);

    }, [merchants.loading, process.loading])

    return useMemo(() => (

        <Panel
            heading={"Request Details"}
        >

            {step === "check" &&
                <RequestsCreateFormCheck
                    form={form}
                    process={merchants}
                    handleSubmit={handleCheck}
                />
            }

            {step === "validate" && merchants.data &&
                <RequestsCreateFormValidate
                    form={form}
                    merchants={merchants.data}
                    process={process}
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                />
            }

            {step === "review" &&
                <RequestsCreateFormReview
                    requests={process.data}
                    handleReset={handleReset}
                />
            }

        </Panel>


    ), [form.hash, step, merchants.loading, process.loading]);

}

export default RequestsCreateForm;
