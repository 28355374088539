import React, { useMemo, } from 'react';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, FieldParseFile, InputTableCell, InputTableRow } from '@dex/bubl-dash';
import { Row } from '@dex/bubl-dash';
import { Line } from '@dex/bubl-dash';
import { loop, slug } from '@dex/bubl-helpers';
import { FieldRelationship } from '@dex/bubl-dash';
import { DataTable } from '@dex/bubl-dash';
import { FieldText } from '@dex/bubl-dash';
import { Space } from '@dex/bubl-dash';
import { FieldPhone } from '@dex/bubl-dash';


const MerchantsImportCheckForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, columns, handleReset } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const parseData = async (data, file, buffer) => {

        const rows: any = [];

        loop(data, (row, index) => {

            rows.push({
                mid: row['mid'],
                companyName: row['company-name'],
                tradingName: row['trading-name'],
                email: row['email-address'],
                phone: row['phone-number'],
                line1: row['shipping-address-line-1'],
                line2: row['shipping-address-line-2'],
                city: row['city'],
                state: row['state'],
                country: row['country'],
                zipcode: row['zip-code'],
            });

        });

        form.reset({ merchants: rows });
        form.setKey();

    };

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldParseFile
                        label={"Import File"}
                        required={true}
                        accept={".xlsx,.xls,.csv"}
                        onFileLoaded={parseData}
                        optsCSV={{
                            header: true,
                            dynamicTyping: false,
                            skipEmptyLines: true,
                            transformHeader: (text, index) => slug(text),
                        }}
                        optsXLS={{
                            toCSV: true,
                            singleSheet: true,
                        }}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldRelationship
                        form={form}
                        label={"Vendor"}
                        name='vendorId'
                        required={true}
                        endpoint={'Vendors'}
                        preload={true}
                        defaultValue={form.defaults.vendorId}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldRelationship
                        form={form}
                        label={"Product"}
                        name='productId'
                        required={true}
                        endpoint={'Products'}
                        preload={true}
                        defaultValue={form.defaults.productId}
                    />

                </Col>

            </Row>

            {form.values.merchants && form.values.merchants.length > 0 &&

                <div style={{ overflow: "auto" }}>

                    <Space />

                    <DataTable
                        data={null}
                        columns={columns}
                        className={"input-table"}
                        style={{ overflowY: "scroll" }}
                    >

                        {form.values.merchants.map((merchant, index) => (

                            <InputTableRow key={index}>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`merchants[${index}].mid`}
                                        defaultValue={merchant.mid}
                                        required={true}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`merchants[${index}].companyName`}
                                        defaultValue={merchant.companyName}
                                        required={true}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`merchants[${index}].tradingName`}
                                        defaultValue={merchant.tradingName}
                                        required={true}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`merchants[${index}].email`}
                                        defaultValue={merchant.email}
                                        required={true}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldPhone
                                        form={form}
                                        name={`merchants[${index}].phone`}
                                        defaultValue={merchant.phone}
                                        required={true}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`merchants[${index}].line1`}
                                        defaultValue={merchant.line1}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`merchants[${index}].line2`}
                                        defaultValue={merchant.line2}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`merchants[${index}].zipcode`}
                                        defaultValue={merchant.zipcode}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`merchants[${index}].city`}
                                        defaultValue={merchant.city}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`merchants[${index}].state`}
                                        defaultValue={merchant.state}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`merchants[${index}].country`}
                                        defaultValue={merchant.country}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                        ))}

                        <Space height={300} />

                    </DataTable>

                </div>

            }

            <Line />

            {form.values.merchants && form.values.merchants.length > 0 && (

                <ButtonRow>

                    <Button
                        label={"Check Merchants"}
                        loading={process.loading}
                    />

                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={handleReset}
                    />

                </ButtonRow>

            )}

        </Form>


    ), [form.hash, form.changed, process.loading]);

}

export default MerchantsImportCheckForm;
