import React, { useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import DashboardReportsAnalysis from './DashboardReportsAnalysis';

const DashboardReportsPanel: React.FC<any> = (props: any) => {

    const [tab, setTab] = useState("analysis");

    const tabs = [
        {
            name: "analysis",
            label: "Paper Roll / Transaction Analysis"
        },
    ];

    return useMemo(() => (

        <Panel
            heading={"Generate Reports"}
            tabs={tabs}
            setTab={setTab}
            activeTab={tab}
        >

            {tab === "analysis" &&
                <DashboardReportsAnalysis />
            }

        </Panel>


    ), [tab]);

}

export default DashboardReportsPanel;
