import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import BulkRequestsReviewView from './BulkRequestReview/BulkRequestReviewView';
import BulkRequestsShippingView from './BulkRequestShipping/BulkRequestShippingView';
import BulkRequestsDeliveryView from './BulkRequestsDelivery/BulkRequestsDeliveryView';

const BulkRequestsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Bulk Request Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Vendor",
                                key: "vendor",
                                format: "modelLink",
                            },
                            {
                                label: "Acquirers Review Needed",
                                key: "reviewNeeded",
                                format: "switch"
                            },
                        ]}
                        columnB={[
                            {
                                label: "Quantity (In Rolls)",
                                key: "quantityInRolls",
                                format: "number"
                            },
                            {
                                label: "Product",
                                key: "productMeta.name",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Number of Rolls",
                                key: "productMeta.numberOfRolls",
                                format: "number"
                            },
                            {
                                label: "Diameter (mm)",
                                key: "productMeta.diameter",
                                format: "number"
                            },
                        ]}
                    />

                </Panel>

                {data.reviewNeeded &&
                    <>
                        <Space />
                        <BulkRequestsReviewView
                            data={data}
                        />
                    </>
                }

                {(!data.reviewNeeded || (data.reviewNeeded && data.reviewStatus === "approved")) &&
                    <>

                        <Space />
                        <BulkRequestsShippingView
                            data={data}
                        />
                    </>
                }

                {data.shippingStatus === "shipped" &&
                    <>
                        <Space />
                        <BulkRequestsDeliveryView
                            data={data}
                        />
                    </>
                }


            </Col>

        </Row>

    ), [data]);

}

export default BulkRequestsSingleView;
