import React, { useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldPhone from '@dex/bubl-dash/src/lib/components/Fields/FieldPhone';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldAddress from '@dex/bubl-dash/src/lib/components/Fields/FieldAddress';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { FieldNumber, Space, useMountEffect } from '@dex/bubl-dash';
import { useApp } from '@dex/bubl-dash';

const MerchantsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    const [settings] = app.store("appSettings");

    useMountEffect(() => {

        if (mode !== "create") return;

        if (settings?.general?.defaultProductId) {

            form.handleChange({ silent: true, reset: true, name: "productId", value: settings.general.defaultProductId });

        }

    });

    return useMemo(() => (

        <Panel
            heading={"Merchant Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"MID"}
                                    name='mid'
                                    required={true}
                                    defaultValue={form.defaults.mid}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>
                                <FieldText
                                    form={form}
                                    label={"Company Name"}
                                    name='companyName'
                                    required={true}
                                    defaultValue={form.defaults.companyName}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Trading Name"}
                                    name='tradingName'
                                    required={false}
                                    defaultValue={form.defaults.tradingName}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Email Address"}
                                    name='email'
                                    required={false}
                                    defaultValue={form.defaults.email}
                                    type={"email"}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldPhone
                                    form={form}
                                    label={"Phone Number"}
                                    name='phone'
                                    required={false}
                                    defaultValue={form.defaults.phone || "+60"}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Product"}
                                    name='productId'
                                    required={true}
                                    preload={true}
                                    endpoint={'Products'}
                                    defaultValue={form.defaults.productId}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Vendor"}
                                    name='vendorId'
                                    required={true}
                                    preload={true}
                                    endpoint={'Vendors'}
                                    defaultValue={form.defaults.vendorId}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "active", label: "Active" },
                                        { value: "inactive", label: "Inactive" },
                                        { value: "limitReached", label: "Limit Reached" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Request Limit"}
                                    name='limitPerQuarter'
                                    required={true}
                                    defaultValue={form.defaults.limitPerQuarter}
                                    placeholder='Number of rolls per month'
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Seperator
                    heading={"Shipping Address"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <FieldAddress
                    form={form}
                    gutter={8}
                    label={"Shipping Address"}
                    name='address'
                    required={true}
                    defaultValue={form.defaults.address}
                    allowedCountries={["Malaysia"]}
                />

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default MerchantsCreateEditForm;
