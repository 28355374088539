import SettingsGeneral from './Components/SettingsGeneral';

export default function (app) {

    app.mods.Settings = {
        groups: [
            {
                key: "general",
                label: "General Settings",
                caps: ["saveSettings"],
                panel: SettingsGeneral
            },
        ]
    }

}