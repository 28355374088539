import React, { useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import BulkRequestReviewForm from './BulkRequestReviewForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import { Alert } from '@dex/bubl-dash';
import { useApp } from '@dex/bubl-dash';

const BulkRequestsReviewView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [showForm, setShowForm] = useState(data.reviewedOnBy ? false : true);

    const app = useApp();

    const canReview = app.userCan(["reviewRequests"]);

    return useMemo(() => (

        <Panel
            heading={"Acquirer Review"}
            actions={data.reviewedOnBy ? (
                <Button
                    label={showForm ? "Cancel" : "Edit"}
                    size="small"
                    type="inline"
                    onClick={() => { setShowForm(!showForm) }}
                />
            ) : null}
        >

            {data.reviewedOnBy &&

                <MetaTable
                    data={data}
                    columnA={[
                        {
                            label: "Review Status",
                            key: "reviewStatus",
                            format: "titleCase",
                        },
                        {
                            label: "Review Notes",
                            key: "reviewNotes",
                        },
                    ]}
                    columnB={[
                        {
                            label: "Reviewed Date",
                            key: "reviewedOnBy",
                            format: "onAndBy",
                        },
                    ]}
                />

            }



            {showForm &&
                <>
                    <Line />

                    {!canReview &&
                        <Alert
                            message="You do not have permission to review requests. Please alert an admin or acquirer."
                            type="warning"
                        />
                    }

                    {canReview &&
                        <BulkRequestReviewForm
                            data={data}
                        />
                    }

                </>
            }

        </Panel>

    ), [data, showForm]);

}

export default BulkRequestsReviewView;
