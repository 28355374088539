import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import ProductsApi from './ProductsApi';
import ProductsActions from './Components/ProductsActions';

let settings;

export default settings = {
    key: 'products',
    zone: 'products',
    path: '/products',
    title: "Products",
    endpoint: ProductsApi.endpoint,
    modelName: 'Products',
    idKey: 'id',
    primaryKey: 'name',
    indexCaps: ["viewProducts"],
    viewCaps: ["viewProducts"],
    createCaps: ["createProducts"],
    indexTitle: (routeName, params, donation) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, donation) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Small",
                path: settings.path + "/boxSize/small",
            },
            {
                label: "Medium",
                path: settings.path + "/boxSize/medium",
            },
            {
                label: "Large",
                path: settings.path + "/boxSize/large",
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, donation) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: ProductsApi.get,
    indexFetchParams: (routeName, params, donation) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.boxSize) filters.where.boxSize = { inq: [params.boxSize] };

        return filters;

    },
    indexQuickSearch: (routeName, params, donation) => {

        const fields = ['id', 'name'];

        return fields;

    },
    indexSearchFields: (routeName, params, donation) => {

        const fields = [
            {
                label: "Product ID",
                key: "id",
                type: "text",
            },
            {
                label: "Product Name",
                key: "name",
                type: "text",
            },
            {
                label: "Diameter",
                key: "diameter",
                type: "number",
            },
            {
                label: "Box Size",
                key: "boxSize",
                type: 'text',
                format: "titleCase",
            },
            {
                label: "Length",
                key: "length",
                type: "number"
            },
            {
                label: "Weight",
                key: "weight",
                type: "number"
            },
            {
                label: "No Of Rolls",
                key: "numberOfRolls",
                type: "number"
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, donation) => {

        const columns = [
            {
                label: "Name",
                key: "this",
                format: "ModelLink",
                sort: 'name'
            },
            {
                label: "Diameter (mm)",
                key: "diameter",
                format: "number",
            },
            {
                label: "Box Size",
                key: "boxSize",
                format: "titleCase",
            },
            {
                label: "Length (m)",
                key: "length",
                format: "number"
            },
            {
                label: "Weight (g)",
                key: "weight",
                format: "number"
            },
            {
                label: "No Of Rolls",
                key: "numberOfRolls",
                format: "number"
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                render: (value, data) => {

                    return <ProductsActions data={data} />;

                }
            }
        ];

        return columns;

    },
    singleFetch: ProductsApi.getOne,
    singleFetchParams: (routeName, params, donation, fetch) => {

        const filters = { include: [] };

        return filters;

    },
    singleTitle: (routeName, params, donation, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, donation, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};