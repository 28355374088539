import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import MerchantsSingleViewRequests from './MerchantsSingleViewRequests';
import { Space } from '@dex/bubl-dash';

const MerchantsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    console.log(data)

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Merchant Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "MID",
                                key: "mid",
                            },
                            {
                                label: "Company Name",
                                key: "companyName",
                                format: "link",
                            },
                            {
                                label: "Trading Name",
                                key: "tradingName",
                                format: "link",
                            },
                            {
                                label: "Vendor",
                                key: "vendor",
                                format: "modelLink"
                            },

                        ]}
                        columnB={[
                            {
                                label: "Phone Number",
                                key: "phone",
                            },
                            {
                                label: "Email Address",
                                key: "email",
                            },
                            {
                                label: "Limit Per Quarter",
                                key: "limitPerQuarter"
                            },
                            {
                                label: "Product",
                                key: "product",
                                format: "modelLink"
                            },

                        ]}
                        columnC={[
                            {
                                label: "Address",
                                key: "address",
                                format: "address"
                            },

                        ]}
                    />

                </Panel>

                <Space />

                <MerchantsSingleViewRequests data={data} />

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>


        </Row>

    ), [data]);

}

export default MerchantsSingleView;
