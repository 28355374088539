import { baseApi, options, response } from "@dex/bubl-fetch";

const endpoint = 'Merchants';

const MerchantsApi: any = baseApi(endpoint);

MerchantsApi.import = (options?: options): response => {

    return MerchantsApi.create(options, "import");

}

export default MerchantsApi;