import React, { useMemo, useCallback } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import DashboardApi from '../DashboardApi';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import moment from "moment";
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import { FieldSwitch, Space } from '@dex/bubl-dash';
import { utils, writeFile } from 'xlsx';
import { loop } from '@dex/bubl-helpers';

const DashboardReportsAnalysis: React.FC<any> = (props: any) => {

    const form = useForm({ type: "merchant", period: "1" }); //mids: "00152455"

    const process = DashboardApi.requestAnalysis();

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values
        });

    }, []);

    const exportCsv = () => {

        const columns = process.data.type === "merchant" ? merchantColumns : vendorColumns;

        const data: any = [];

        loop(process.data.data, (row) => {

            if (process.data.type === "merchant") {

                data.push({
                    [merchantColumns[0].label]: row.merchant?.companyName,
                    [merchantColumns[1].label]: row.merchant?.mid,
                    [merchantColumns[2].label]: row.rolls,
                });

            } else if (process.data.type === "vendor") {

                data.push({
                    [vendorColumns[0].label]: row.vendor?.companyName,
                    [vendorColumns[1].label]: row.rolls,
                });

            }

        });

        const worksheet = utils.json_to_sheet(data, { header: columns.map(item => item.label) });

        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheet, 'Sheet');

        const fileName = process.data.type === "merchant" ? "Merchants" : "Vendors";

        writeFile(workbook, `${fileName}-Export.xlsx`);

    };

    const merchantColumns = [
        {
            label: "Merchant Name",
            key: "merchant",
            format: "ModelLink",
        },
        {
            label: "MID",
            key: "merchant.mid"
        },
        {
            label: "Paper Rolls Delivered",
            key: "rolls",
            format: "number",
            suffix: " Rolls",
            width: 90
        },
    ];

    const vendorColumns = [
        {
            label: "Vendor Name",
            key: "vendor",
            format: "ModelLink",
        },
        {
            label: "Paper Rolls Delivered",
            key: "rolls",
            format: "number",
            suffix: " Rolls",
            width: 90
        },
    ];

    return useMemo(() => (

        <>

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRadio
                            form={form}
                            label={"Request Type"}
                            name='type'
                            required={true}
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "merchant", label: "Merchant Request" },
                                { value: "vendor", label: "Vendor Request" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldRadio
                            form={form}
                            label={"Time Period"}
                            name='period'
                            required={true}
                            defaultValue={form.defaults.period}
                            options={[
                                { value: "1", label: "1 Month" },
                                { value: "3", label: "3 Months" },
                                { value: "6", label: "6 Months" },
                                { value: "12", label: "12 Months" },
                            ]}
                        />

                    </Col>

                    {form.values.type === "merchant" &&
                        <Col col={{ xs: 24 }}>

                            <FieldSwitch
                                form={form}
                                label={"All Merchants"}
                                name='all'
                                defaultValue={form.values.all}
                            />

                        </Col>
                    }

                    {form.values.type === "merchant" && !form.values.all &&
                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Merchant MID"}
                                name='mids'
                                required={true}
                                placeholder={"MID (one per line)"}
                                defaultValue={form.defaults.mids}
                            />

                        </Col>
                    }

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

            {
                process.data &&
                <>

                    {process.data.type === "merchant" &&
                        <>
                            <Seperator
                                top={"auto"}
                                bottom={"auto"}
                                heading={"Merchant Requests"}
                                description={"From " + moment(process.data.date).format("D MMMM YYYY") + " till today."}
                            />

                            <Button
                                label={"Export CSV"}
                                size={"small"}
                                onClick={exportCsv}
                            />

                            <Space />

                            <DataTable
                                data={process.data.data}
                                loading={process.loading}
                                columns={merchantColumns}
                            />

                        </>
                    }

                    {process.data.type === "vendor" &&
                        <>
                            <Seperator
                                top={"auto"}
                                bottom={"auto"}
                                heading={"Vendor Requests"}
                                description={"1 Year since " + moment(process.data.date).format("D MMMM YYYY")}
                            />

                            <Button
                                label={"Export CSV"}
                                size={"small"}
                                onClick={exportCsv}
                            />

                            <Space />

                            <DataTable
                                data={process.data.data}
                                loading={process.loading}
                                columns={vendorColumns}
                            />

                        </>
                    }

                </>
            }
        </>

    ), [form, process]);

}

export default DashboardReportsAnalysis;
