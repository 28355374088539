import React, { useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import RequestReviewForm from './RequestsDeliveryForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';

const RequestsDeliveryView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [showForm, setShowForm] = useState(data.deliveryStatus === "delivered" ? false : true);

    return useMemo(() => (

        <Panel
            heading={"Delivery"}
            actions={data.deliveryOnBy ? (
                <Button
                    label={showForm ? "Cancel" : "Edit"}
                    size="small"
                    type="inline"
                    onClick={() => { setShowForm(!showForm) }}
                />
            ) : null}
        >

            {data.deliveryOnBy &&

                <MetaTable
                    data={data}
                    columnA={[
                        {
                            label: "Delivery Status",
                            key: "deliveryStatus",
                            format: "titleCase",
                        },
                    ]}
                    columnB={[
                        {
                            label: "Delivered On",
                            key: "deliveredOn",
                            format: "date",
                        },
                    ]}
                    columnC={[
                        {
                            label: "Last Updated On",
                            key: "deliveryOnBy",
                            format: "onAndBy",
                        },
                    ]}
                />

            }

            {showForm &&
                <>
                    <Line />
                    <RequestReviewForm
                        data={data}
                    />
                </>
            }


        </Panel>

    ), [data, showForm]);

}

export default RequestsDeliveryView;
