import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import ShippingProvidersApi from './ShippingProvidersApi';
import ShippingProvidersActions from './Components/ShippingProvidersActions';
import { WhiteSpace } from '@dex/bubl-dash';
import { Status } from '@dex/bubl-dash';

let settings;

export default settings = {
    key: 'shippingProviders',
    zone: 'lists',
    path: '/shippingProviders',
    title: "Shipping Providers",
    endpoint: ShippingProvidersApi.endpoint,
    modelName: 'ShippingProviders',
    idKey: 'id',
    primaryKey: 'name',
    indexCaps: ["viewShippingProviders"],
    viewCaps: ["viewShippingProviders"],
    createCaps: ["createShippingProviders"],
    indexTitle: (routeName, params, donation) => {

        let title: string = settings.title;

        if (params && params.amount) title = title + " / " + titleCase(params.amount);

        return title;

    },
    indexTabs: (routeName, params, donation) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Active",
                path: settings.path + "/status/active",
            },
            {
                label: "Inactive",
                path: settings.path + "/status/inactive",
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, donation) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: ShippingProvidersApi.get,
    indexFetchParams: (routeName, params, donation) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        filters.include = [];


        return filters;

    },
    indexQuickSearch: (routeName, params, donation) => {

        const fields = ['id', 'name', 'shippingName', 'shippingAddress', 'primaryContact.name', 'primaryContact.email', 'primaryContact.phone'];

        return fields;

    },
    indexSearchFields: (routeName, params, donation) => {

        const fields = [
            {
                label: "ID",
                key: "id",
                type: "text",
            },
            {
                label: "Name",
                key: "name",
                type: "text",
            },
            {
                label: "Shipping Name",
                key: "shippingName",
                type: "text",
            },
            {
                label: "Shipping Address",
                key: "shippingAddress",
                type: "text",
            },
            {
                label: "Primary Contact Name",
                key: "primaryContact.name",
                type: "text",
            },
            {
                label: "Primary Contact Email",
                key: "primaryContact.email",
                type: "text",
            },
            {
                label: "Primary Contact Phone",
                key: "primaryContact.phone",
                type: "number",
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, donation) => {

        const columns = [
            {
                label: "Name",
                key: "this",
                format: "ModelLink",
                sort: 'name'
            },
            {
                label: "Status",
                key: "status",
                format: "status",
            },
            {
                label: "Primary Contact Name",
                key: "primaryContact.name",
                type: "text",
            },
            {
                label: "Primary Contact Email",
                key: "primaryContact.email",
                type: "text",
            },
            {
                label: "Primary Contact Phone",
                key: "primaryContact.phone",
                type: "number",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                render: (value, data) => {

                    return <ShippingProvidersActions data={data} />;

                }
            }
        ];

        return columns;

    },
    singleFetch: ShippingProvidersApi.getOne,
    singleFetchParams: (routeName, params, donation, fetch) => {

        const filters = { include: [] };

        return filters;

    },
    singleTitle: (routeName, params, donation, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return <>{title}<WhiteSpace />{data && <Status status={data?.status} prefix='providers.status' />}</>;

    },
    singleTabs: (routeName, params, donation, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};