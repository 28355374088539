import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import RequestsApi from '../../RequestsApi';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';

const RequestsDeliveryForm: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const [nav] = app.store('nav');

    const form = useForm({
        deliveryStatus: data.deliveryStatus,
        deliveredOn: data.deliveredOn
    });

    const process = RequestsApi.update(data.id, 'delivery', {
        onComplete: (data) => {

            //refresh view

            //temporary solution
            app.navigate(nav, 'update', {});

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values
        });

    }, [])

    console.log(data, form.defaults);


    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldRadio
                        form={form}
                        label={"Delivery Status"}
                        name='deliveryStatus'
                        required={true}
                        defaultValue={form.defaults.deliveryStatus}
                        options={[
                            { value: "pending", label: "Pending" },
                            { value: "delivered", label: "Delivered" },
                            { value: "returned", label: "Returned" },
                        ]}
                    />

                </Col>

                {form.values.deliveryStatus === "delivered" &&
                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldDate
                            form={form}
                            label={"Delivered On"}
                            name='deliveredOn'
                            required={true}
                            defaultValue={form.defaults.deliveredOn}
                        />

                    </Col>
                }



            </Row>

            <Line />

            <ButtonRow>
                <Button
                    label={"Submit"}
                    loading={process.loading}
                />
                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={form.reset}
                />
            </ButtonRow>

        </Form>


    ), [data, form, process]);

}

export default RequestsDeliveryForm;
