import React, { useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import BulkRequestShippingForm from './BulkRequestShippingForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';

const BulkRequestsShippingView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [showForm, setShowForm] = useState(data.shippingStatus === "shipped" ? false : true);

    return useMemo(() => (

        <Panel
            heading={"Packaging & Shipping"}
            actions={data.shippingOnBy ? (
                <Button
                    label={showForm ? "Cancel" : "Edit"}
                    size="small"
                    type="inline"
                    onClick={() => { setShowForm(!showForm) }}
                />
            ) : null}
        >

            {data.shippingOnBy &&

                <MetaTable
                    data={data}
                    columnA={[
                        {
                            label: "Shipping Status",
                            key: "shippingStatus",
                            format: "titleCase",
                        },
                        {
                            label: "Shipping Provider",
                            key: "shippingProvider",
                            format: "modelLink",
                        },
                    ]}
                    columnB={[
                        {
                            label: "Tracking No.",
                            key: "trackingNo",
                        },
                        {
                            label: "Shipped On",
                            key: "shippedOn",
                            format: "date",
                        },
                    ]}
                    columnC={[
                        {
                            label: "Last Updated On",
                            key: "shippingOnBy",
                            format: "onAndBy",
                        },
                    ]}
                />

            }

            {showForm &&
                <>
                    <Line />
                    <BulkRequestShippingForm
                        data={data}
                    />
                </>
            }

        </Panel>

    ), [data, showForm]);

}

export default BulkRequestsShippingView;
