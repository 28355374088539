import React, { useMemo, useState } from 'react';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import RequestsSettings from '../../Requests/RequestsSettings';

const MerchantsSingleViewRequests: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, user, app) => {

        const filters = RequestsSettings.indexFetchParams(routeName, params, user, app);

        filters.where.merchantId = data.id;

        return filters;

    };

    return useMemo(() => (

        <>
            <IndexTable
                title={"Merchant Requests"}
                getFetch={RequestsSettings.indexFetch}
                getColumns={RequestsSettings.indexColumns}
                preFetchFilters={indexFetchParams}
                getQuickSearch={RequestsSettings.indexQuickSearch}
                getSearchFields={RequestsSettings.indexSearchFields}
                getExportFormats={RequestsSettings.indexExportFormats}
                routeParams={tableState}
                manageState={setTableState}
            />

        </>

    ), [data]);

}

export default MerchantsSingleViewRequests;
