import React, { useMemo, useCallback } from 'react';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import BulkRequestsApi from '../../BulkRequestsApi';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';

const BulkRequestReviewForm: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const [nav] = app.store('nav');

    const form = useForm({
        reviewStatus: data.reviewStatus,
        reviewNotes: data.reviewNotes,
        productId: data.productId
    });

    const process = BulkRequestsApi.update(data.id, 'review', {
        onComplete: (data) => {

            //refresh view

            //temporary solution
            app.navigate(nav, 'update', {});

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values
        });

    }, [])

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldRadio
                        form={form}
                        label={"Review Status"}
                        name='reviewStatus'
                        required={true}
                        defaultValue={form.defaults.reviewStatus}
                        options={[
                            { value: "approved", label: "Approved" },
                            { value: "denied", label: "Denied" },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <FieldTextArea
                        form={form}
                        label={"Review Notes"}
                        name='reviewNotes'
                        defaultValue={form.defaults.reviewNotes}
                    />

                </Col>

            </Row>

            <Line />

            <ButtonRow>
                <Button
                    label={"Submit"}
                    loading={process.loading}
                />
                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={form.reset}
                />
            </ButtonRow>

        </Form>

    ), [data, form, process]);

}

export default BulkRequestReviewForm;
