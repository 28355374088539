import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';

const ProductsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Product Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Product Name"}
                            name='name'
                            required={true}
                            defaultValue={form.defaults.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Box Size"}
                            name='boxSize'
                            required={true}
                            defaultValue={form.defaults.boxSize}
                            options={[
                                { value: "small", label: "Small" },
                                { value: "medium", label: "Medium" },
                                { value: "large", label: "Large" },
                                { value: "X Large", label: "X Large" },
                                { value: "XX Large", label: "XX Large" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldNumber
                            form={form}
                            label={"Number Of Rolls"}
                            name='numberOfRolls'
                            required={false}
                            defaultValue={form.defaults.numberOfRolls}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldNumber
                            form={form}
                            label={"Length (m)"}
                            name='length'
                            required={true}
                            defaultValue={form.defaults.length}
                        />

                    </Col>


                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldNumber
                            form={form}
                            label={"Diameter (mm)"}
                            name='diameter'
                            required={true}
                            defaultValue={form.defaults.diameter}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldNumber
                            form={form}
                            label={"Weight (g)"}
                            name='weight'
                            required={true}
                            defaultValue={form.defaults.weight}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default ProductsCreateEditForm;
