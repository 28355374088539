import React, { useMemo } from 'react';
import { Box } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import DashboardReportsPanel from './DashboardReportsPanel';

const DashboardIndex: React.FC<any> = (props: any) => {

    return useMemo(() => (

        <Box gutter={"auto"}>

            <DashboardReportsPanel />

        </Box>

    ), []);

}

export default DashboardIndex;
