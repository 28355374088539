import React from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldTextArea } from '@dex/bubl-dash';

const RequestsCreateFormCheck: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    return (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 16 }}>

                    <FieldTextArea
                        form={form}
                        name={'merchantIds'}
                        label={"Merchant MID's"}
                        required={true}
                        rows={10}
                        placeholder={"Enter Merchant MID's. Each MID on a new line."}
                    />

                </Col>

            </Row>

            <Line />

            <ButtonRow>
                {!process.data &&
                    <Button
                        label={"Check Merchant MID's"}
                        loading={process.loading}
                    />
                }
            </ButtonRow>

        </Form>

    )

}

export default RequestsCreateFormCheck;
