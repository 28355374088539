import React from 'react';

import BublDash from '@dex/bubl-dash/src/lib/Dash';
import Media from '@dex/bubl-dash/src/lib/views/Media/Media';
import Users from '@dex/bubl-dash/src/lib/views/Users/Users';
import Defaults from '@dex/bubl-dash/src/lib/views/Defaults';
import Wrapper from '@dex/bubl-dash/src/lib/Wrapper';

// import MerchantsIcon from './assets/Merchants.svg';
// import ListsIcon from './assets/Lists.svg';
// import ProductsIcon from './assets/Products.svg';
// import FinanceIcon from './assets/Finance.svg';
// import ReportsIcon from './assets/Reports.svg';
// import PricingIcon from "./assets/Pricing.svg";

import ProductsBlack from './assets/Products-Black.svg';
import ProductsWhite from './assets/Products-White.svg';
import LogisticsBlack from './assets/Logistics-Black.svg';
import LogisticsWhite from './assets/Logistics-White.svg';
import './styles/App.scss';
import Merchants from './views/Merchants/Merchants';
import Vendors from './views/Vendors/Vendors';
import ShippingProviders from './views/ShippingProviders/ShippingProviders';
import Products from "./views/Products/Products";
import Dashboard from "./views/Dashboard/Dashboard";
import Requests from "./views/Requests/Requests";
import BulkRequests from "./views/BulkRequests/BulkRequests";
import SettingsMods from './views/Settings/SettingsMods';

const app = new BublDash({
    name: "Paper Dash",
    icon: "assets/logo.png",
    accessRoles: ['admin', 'super', "factoryManager", "acquirer", "vendor", 'factoryProduction', 'merchant'], //must be one of these roles in order to sign in
    userRoles: [
        {
            key: 'factoryManager',
            label: 'Factory Manager'
        },
        {
            key: 'factoryProduction',
            label: 'Factory Production'
        },
        {
            key: 'acquirer',
            label: 'Acquirer'
        },
        {
            key: 'vendor',
            label: 'Vendor'
        },
        {
            key: 'admin',
            label: 'Admin'
        },
        {
            key: 'super',
            label: 'Super'
        },
        {
            key: 'merchant',
            label: 'Merchant'
        },
    ],
    env: window['bublENV'] || "development", //set env by modifying the output sent by express server
});

app.addZone({
    key: "products",
    label: "Products",
    priority: 13,
    icon: ProductsBlack,
    iconActive: ProductsWhite,
});

app.addZone({
    key: "dashboard",
    label: "Dash",
    priority: 8,
});

app.addZone({
    key: "requests",
    label: "Requests",
    priority: 11,
    icon: LogisticsBlack,
    iconActive: LogisticsWhite,
});


//Add New Models Here
const extensions: any = [
    SettingsMods,
    Defaults,
    Merchants,
    Products,
    Requests,
    Vendors,
    Dashboard,
    ShippingProviders,
    BulkRequests,
    Users,
    Media,
];

extensions.map(extension => {

    extension(app);

    return null;

});

const App: React.FC = () => {

    return (
        <Wrapper>
            {app.render()}
        </Wrapper>
    )

}

export default App;