import { Seperator } from '@dex/bubl-dash';
import { FieldText } from '@dex/bubl-dash';
import { FieldRelationship } from '@dex/bubl-dash';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';

const SettingsGeneral: React.FC<any> = (props: any) => {

    const { form } = props;

    return useMemo(() => (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldRelationship
                        form={form}
                        label={"Default Product"}
                        name='defaultProductId'
                        required={true}
                        preload={true}
                        endpoint={'Products'}
                        defaultValue={form.defaults.defaultProductId}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldRelationship
                        form={form}
                        label={"Default Vendor Product"}
                        name='defaultVendorProductId'
                        required={true}
                        preload={true}
                        endpoint={'Products'}
                        defaultValue={form.defaults.defaultVendorProductId}
                    />

                </Col>

            </Row>

            <Seperator
                top={"auto"}
                bottom={"auto"}
                heading={"Acquirer Settings"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Contact Person Name"}
                        name='contactPersonName'
                        required={true}
                        defaultValue={form.defaults.contactPersonName}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Contact Person Email"}
                        name='contactPersonEmail'
                        required={true}
                        defaultValue={form.defaults.contactPersonEmail}
                    />

                </Col>

            </Row>

        </>

    ), [form]);

}

export default SettingsGeneral;
