import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldAddress from '@dex/bubl-dash/src/lib/components/Fields/FieldAddress';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { FieldNumber } from '@dex/bubl-dash';


const VendorsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Vendors Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>
                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Vendors Name"}
                                    name='companyName'
                                    required={true}
                                    defaultValue={form.defaults.companyName}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Primary Contact Name"}
                                    name='primaryContact.name'
                                    required={false}
                                    defaultValue={form.defaults.primaryContact ? form.defaults.primaryContact.name : null}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Primary Contact Email"}
                                    name='primaryContact.email'
                                    required={false}
                                    defaultValue={form.defaults.primaryContact ? form.defaults.primaryContact.email : null}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Primary Contact Phone"}
                                    name='primaryContact.phone'
                                    required={false}
                                    defaultValue={form.defaults.primaryContact ? form.defaults.primaryContact.phone : null}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "active", label: "Active" },
                                        { value: "inactive", label: "Inactive" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Request Limit"}
                                    name='limitPerQuarter'
                                    required={true}
                                    defaultValue={form.defaults.limitPerQuarter}
                                    placeholder='Number of rolls per month'
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Seperator
                    heading={"Shipping Address"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <FieldAddress
                    form={form}
                    gutter={8}
                    label={"Shipping Address"}
                    name='address'
                    required={true}
                    defaultValue={form.defaults.address}
                    allowedCountries={["Malaysia"]}
                />

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default VendorsCreateEditForm;
