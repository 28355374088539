import React, { useCallback, } from 'react';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Alert, Space } from '@dex/bubl-dash';
import { DataTable } from '@dex/bubl-dash';

const RequestsCreateFormReview: React.FC<any> = (props: any) => {

    const { requests, handleReset } = props;

    const columns = [
        {
            label: "MID",
            key: "mid",
        },
        {
            label: "Merchant",
            key: "merchant",
            format: "ModelLink",
        },
        {
            label: "Message",
            key: "message",
        },
        {
            label: "Request",
            key: "request",
            format: "ModelLink"
        },
    ];

    const getClassByStatus = useCallback((row) => {

        return row.status ? "table-bg-green" : "table-bg-red";

    }, [])

    return (

        <>

            {requests &&
                <>

                    {requests.success > 0 &&
                        <>
                            <Alert
                                type={"success"}
                                description={requests.success + " Requests Successfully Created."}
                            />
                            <Space />
                        </>
                    }

                    {requests.error > 0 &&
                        <>
                            <Alert
                                type={"error"}
                                description={requests.error + " Errors Occurred. Please check the list below."}
                            />
                            <Space />
                        </>
                    }

                    <DataTable
                        data={requests.list}
                        columns={columns}
                        rowClassName={getClassByStatus}
                    />

                </>
            }

            <Line />

            <ButtonRow>

                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={handleReset}
                />

            </ButtonRow>

        </>


    );

}

export default RequestsCreateFormReview;
